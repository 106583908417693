import React, {useState, useMemo} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableViewIcon from '@mui/icons-material/TableView';
import EnhancedTable from '../../components/EnhancedTable'
import StyledCard from '../../components/StyledCard'
import { MoneyFormat } from '../../components/MoneyFormat';
import moment from 'moment';
import _ from 'underscore'

export default function SummarySalesTable({ orders }) {
  const [showThisYear, setShowThisYear] = useState(true);
  const [displayYear, setDisplayYear] = useState(moment().startOf('year'));

  const monthlySales = useMemo(() => {
    const monthlyFormat = 'YYYY-MM';
    let groupedByMonthData = _.groupBy(orders, d => {
      return moment.unix(d.date).utc().format(monthlyFormat);
    });
    let preparedMonthlyData = [];
    let consideringMoment = displayYear.clone();
    for ( let i = 0 ; i < 12; i++ ) {
      let monthlyKey = consideringMoment.format(monthlyFormat);
      // create empty data
      let monthlyData = {
        name: i,
        month: consideringMoment.format('MMMM'),
        value: 0,
        shipping_cost: 0,
        payment_received: 0,
        outstanding: 0,
        outstanding_shipped: 0
      };

      if ( groupedByMonthData.hasOwnProperty(monthlyKey) ) {
        let rawdata = groupedByMonthData[monthlyKey];
        monthlyData.value = rawdata.reduce((sum, cur) => sum + (cur.value || 0), 0);
        monthlyData.shipping_cost = rawdata.reduce((sum, cur) => sum + (cur.shipping_cost || 0), 0);
        monthlyData.payment_received = rawdata.reduce((sum, cur) => sum + (cur.payment_advance || 0) + (cur.payment_full || 0), 0);
        monthlyData.outstanding = rawdata.reduce((sum, cur) => sum + (cur.shipping_status !== 'done' ? (cur.value - (cur.payment_advance || 0) - (cur.payment_full || 0)) : 0), 0);
        monthlyData.outstanding_shipped = rawdata.reduce((sum, cur) => sum + (cur.shipping_status === 'done' ? (cur.value - (cur.payment_advance || 0) - (cur.payment_full || 0)) : 0), 0);
        monthlyData.valueWoShipping = monthlyData.value - monthlyData.shipping_cost;
      }

      preparedMonthlyData = [
        monthlyData,
        ...preparedMonthlyData
      ];

      consideringMoment = consideringMoment.add(1, 'months');
    }

    return preparedMonthlyData;
  }, [orders, displayYear]);

  const priceFormat = (v) => v ? <MoneyFormat currency="$" value={v}/> : '-';
  const moneyColSx = { maxWidth: '120px' };
  const columns = [
    {
      id: 'month',
      label: 'Monthly',
      summary: 'Total'
    },
    {
      id: 'value',
      label: 'Order Value',
      format: priceFormat,
      summary: true,
      sx: moneyColSx
    },
    {
      id: 'valueWoShipping',
      label: 'Total Sale (without shipping)',
      format: priceFormat,
      summary: true,
      sx: moneyColSx
    },
    {
      id: 'payment_received',
      label: 'Amount Received',
      format: priceFormat,
      summary: true,
      sx: moneyColSx
    },
    {
      id: 'outstanding',
      label: 'Outstanding Payments (Not Shipped)',
      format: priceFormat,
      summary: true,
      sx: moneyColSx
    },
    {
      id: 'outstanding_shipped',
      label: 'Outstanding Credit Payments (Already Shipped)',
      format: priceFormat,
      summary: true,
      sx: moneyColSx
    },
    {
      id: 'shipping_cost',
      label: 'Shipping Charge',
      format: priceFormat,
      summary: true,
      sx: moneyColSx
    }
  ];

  const yearText = displayYear.format('YYYY');
  const buttonText = `Show ${displayYear.clone().add(showThisYear ? -1 : 1, 'year').format('YYYY')}`;
  return (
    <StyledCard
      title={`Sales in ${yearText}`}
      titleIcon={TableViewIcon}
      titleAction={
        <Button
          sx={{mt: 0.5, mr: 1}}
          size="small"
          onClick={() => {
            const newYear = displayYear.clone().add(showThisYear ? -1 : 1, 'year');
            setDisplayYear(newYear);
            setShowThisYear(!showThisYear);
          }}
        >
          {buttonText}
        </Button>
      }
    >
      <Box>
        <EnhancedTable border stickyColumn columns={columns} items={monthlySales} noPagination/>
      </Box>
    </StyledCard>
  );
}
