import { ajaxCall, formRequestData } from './ajaxService';
import {getRoleUrl} from '../helpers';

const constants = window.getConfig;

export const ajaxGenLicense = (params) =>
  new Promise((res, rej) => {
    const ajaxLicenseUrl = `${constants.ajax.baseUrl}/${getRoleUrl('genLicense')}`;
    ajaxCall(ajaxLicenseUrl, formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

export const ajaxGenActivateCode = (params) =>
  new Promise((res, rej) => {
    const ajaxLicenseUrl = `${constants.ajax.baseUrl}/${getRoleUrl('genActivateCode')}`;
    ajaxCall(ajaxLicenseUrl, formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });
