import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Page from '../../components/Page';
import LoadingSpinner from '../../components/LoadingSpinner';
import StyledMainGrid from '../../components/StyledMainGrid';
import { ajaxGetOrdersWithoutCancelByTime } from '../../services/orderService';
import { ajaxGetDealersAutocomplete } from '../../services/dealerService';
import SummarySales from './SummarySales';
import DealerSales from './DealerSales';
import AvailableCredit from './AvailableCredit';
import SummarySalesTable from './SummarySalesTable';
import RequestToDelete from './RequestToDelete'
import inMemoryUser from '../../services/inMemoryUser';
import moment from 'moment';

export default function Dashboard() {
  const navigate = useNavigate();
  const incomingParams = useParams();
  const [dealerId, setDealerId] = useState(Number(incomingParams.dealerId) ? Number(incomingParams.dealerId) : -1);
  const [autocomplete, setAutocomplete] = useState(null);
  const [displayInfo, setDisplayInfo] = useState(false);

  useEffect(() => {
    setDisplayInfo(false);
    setDealerId(Number(incomingParams.dealerId) ? Number(incomingParams.dealerId) : -1);
  }, [incomingParams.dealerId]);

  useEffect(() => {
    ajaxGetDealersAutocomplete().then((dealerRes) => {
      ajaxGetOrdersWithoutCancelByTime({
        start: moment().subtract(2, 'years').unix(),
        end: -1
      }).then((orderRes) => {
        const dealerList = dealerRes.data;
        const orderList = orderRes.data;
        const autocompleteList = [
          { id: -1, name: 'All Companies' },
          ...dealerList.map(d => ({ id: d.id, name: d.name }))
        ]
        const info = {
          autocompleteList: [...autocompleteList],
          orders: dealerId === -1 ? [...orderList] : orderList.filter(o => o.dealer_id === dealerId),
          dealers: [...dealerList]
        };

        setDisplayInfo(info);
        setAutocomplete(autocompleteList.find(d => d.id === dealerId));
      })
      .catch(() => {
        setDisplayInfo(false);
        setAutocomplete(null);
      });
    }).catch(() => {
      setDisplayInfo(false);
      setAutocomplete(null);
    });
  }, [dealerId]);

  const handleDealerNameSearch = (event, value) => {
    setAutocomplete(value);
    if ( value ) {
      navigate(`/dashboard/${value.id}`);
    }
  };

  return (
    <Page title="Overview">
      <StyledMainGrid columnSpacing alignItems="flex-start" sx={{ pb: '40px' }}>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            sx={{ width: '100%' }}
            size="small"
            value={autocomplete}
            options={displayInfo ? displayInfo.autocompleteList : []}
            isOptionEqualToValue={(option, value) => (value && option.id === value.id)}
            getOptionLabel={(option) => `${option.name} [${option.id}]`}
            onChange={handleDealerNameSearch}
            renderInput={(params) => <TextField {...params} label="Dealer Name" />}
          />
        </Grid>
        {
          !displayInfo &&
          <Grid container item xs={12} sx={{ position: 'relative', height: '50vh' }}>
            <LoadingSpinner />
          </Grid>
        }
        {
          displayInfo && 
          <Grid container item xs={12} rowSpacing="22px" columnSpacing="22px">
            <Grid item xs={12} sm={6}>
              <SummarySales orders={displayInfo.orders} />
            </Grid>
            <Grid item xs={12} sm={6}>
              {
                dealerId === -1 ?
                <DealerSales orders={displayInfo.orders} dealers={displayInfo.dealers} />
                :
                <AvailableCredit dealerId={dealerId} />
              }
            </Grid>
            <Grid item {...(inMemoryUser.isAdmin() ? { xs: 12, sm: 9 } : { xs: 12 })}>
              <SummarySalesTable orders={displayInfo.orders}/>
            </Grid>
            {
              inMemoryUser.isAdmin() &&
              <Grid item xs={12} sm={3}>
                <RequestToDelete/>
              </Grid>
            }
          </Grid>
        }
      </StyledMainGrid>
    </Page>
  );
}
