import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import StyledMainGrid from '../components/StyledMainGrid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import { ajaxGenLicense, ajaxGenActivateCode } from '../services/licenseService';
import validator from 'validator';
import Page from '../components/Page';
import StyledCard from '../components/StyledCard'
import StyledCardFormContent from '../components/StyledCardFormContent';
import {isMacAddress, formatMacAddress, isPhone, isFloat, noty, copyToClipboard} from '../helpers';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function License(props) {

  const [license, setLicense] = useState({license_type: 'license', pro: 'basic', sensors: 0, vsens: 0, '5dc': 0,});
  const [output, setOutput] = useState('N/A');
  const [isOutputError, setIsOutputError] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const allFields = useMemo(
    () => {
      return [
      {
        key: 'mac',
        type: 'text',
        label: 'MAC Address',
        autoFocus: true
      },
      {
        key: 'license_type',
        type: 'radio',
        label: 'Generate',
        dataset: [{value: 'license', label: 'License'}, {value: 'activate', label: 'Activation Key'}]
      },
      {
        key: 'pro',
        type: 'radio',
        label: 'Basic/PRO',
        dataset: [{value: 'basic', label: 'Basic'}, {value: 'pro', label: 'PRO'}]
      },
      {
        key: 'sensors',
        type: 'text',
        label: 'Sensor Ports (255 for Unlimited)',
        min: 0,
        max: 255
      },
      {
        key: 'vsens',
        type: 'text',
        label: 'Virtual Sensors (255 for Unlimited)',
        min: 0,
        max: 255
      },
      {
        key: '5dc',
        type: 'text',
        label: '5 DC Inputs (255 for Unlimited)',
        min: 0,
        max: 255
      },
      {
        key: 'radius',
        type: 'checkbox',
        label: 'RADIUS',
        gridProps: { xs: 3 }
      },
      {
        key: 'tacacs',
        type: 'checkbox',
        label: 'TACACS',
        gridProps: { xs: 9 }
      },
      {
        key: 'spxn_dry10',
        type: 'checkbox',
        label: 'SPXN Dry Contact 10',
        gridProps: { xs: 3 }
      },
      {
        key: 'spxn_relays',
        type: 'checkbox',
        label: 'SPXN Relays',
        gridProps: { xs: 3 }
      },
      {
        key: 'spxn_vdc',
        type: 'checkbox',
        label: 'SPXN VDC Inputs',
        gridProps: { xs: 6 }
      },
      {
        key: 'sp1plus_temp',
        type: 'checkbox',
        label: 'SP1+ Temperature',
        gridProps: { xs: 3 }
      },
      {
        key: 'sp1plus_dcmr',
        type: 'checkbox',
        label: 'SP1+ Dry Contact or Mini Relay',
        gridProps: { xs: 9 }
      },
      {
        key: 'sp2pluslcd_temp',
        type: 'checkbox',
        label: 'SP2+LCD Temperature',
        gridProps: { xs: 3 }
      },
      {
        key: 'sp2pluslcd_dc',
        type: 'checkbox',
        label: 'SP2+LCD Dry Contact',
        gridProps: { xs: 9 }
      }
    ]}
    ,
    []
  );

  const requiredFields = useMemo(() => ['mac', 'sensors', 'vsens', '5dc'], []);
  const macFields = useMemo(() => ['mac'], []);
  const emailFields = useMemo(() => [], []);
  const phoneFields = useMemo(() => [], []);
  const floatFields = useMemo(() => [], []);

  const [errors, setErrors] = useState({});

  const validateFields = useCallback(() => {
    const fields = { ...license };
    let newErros = {};
    let result = true;
    for (let index in allFields) {
      let key = allFields[index].key;
      let minLength = allFields[index].minLength;
      let confirm = allFields[index].confirm;
      let min = allFields[index].min;
      let max = allFields[index].max;
      let fieldValue = fields[key] !== undefined && fields[key] !== null ? fields[key] + '' : '';
      let fieldConfirmValue = confirm && fields[confirm] ? fields[confirm] + '' : '';
      if (requiredFields.includes(key) && validator.isEmpty(fieldValue, { ignore_whitespace: true })) {
        newErros[key] = 'required field';
        result = false;
      } else if (fieldValue && minLength > 0 && !validator.isLength(fieldValue, { min: minLength, max: undefined })) {
        newErros[key] = `This field must be at least ${minLength} characters in length`;
        result = false;
      } else if (fieldValue && emailFields.includes(key) && !validator.isEmail(fieldValue)) {
        newErros[key] = 'Please enter a valid email address';
        result = false;
      } else if (fieldValue && phoneFields.includes(key) && !isPhone(fieldValue)) {
        newErros[key] = 'Please enter a valid phone number';
        result = false;
      } else if (fieldValue && floatFields.includes(key) && !isFloat(fieldValue)) {
        newErros[key] = 'Please enter a valid number';
        result = false;
      } else if (confirm && fieldValue !== fieldConfirmValue) {
        newErros[key] = 'Please enter the same value again.';
        result = false;
      } else if (fieldValue && !isNaN(min) && !isNaN(max) && (isNaN(fieldValue) || parseFloat(fieldValue) < min || parseFloat(fieldValue) > max)) {
        newErros[key] = `Please enter a value between ${min} and ${max}.`;
        result = false;
      } else if (fieldValue && macFields.includes(key) && !isMacAddress(fieldValue)) {
        newErros[key] = 'Please enter a valid MAC address';
        result = false;
      }
    }
    setErrors(newErros);
    return result;
  }, [license, allFields, requiredFields, emailFields, phoneFields, floatFields, macFields]);

  const handleSave = () => {
    if (!validateFields()) return false;

    let params = {
      mac: formatMacAddress(license.mac)
    };

    let licenseList = [];
    if ( license.license_type === 'license' ) {
      if ( license.pro === 'pro' ) {
        licenseList.push({code: 'pro', value: true});
      }
      const intList = ['sensors', 'vsens', '5dc'];
      intList.forEach( c => {
        if ( license[c] > 0 ) {
          licenseList.push({code: c, value: parseInt(license[c])});
        }
      });

      const boolList = ['radius', 'tacacs', 'spxn_dry10', 'spxn_relays', 'spxn_vdc', 'sp1plus_temp', 'sp1plus_dcmr', 'sp2pluslcd_temp', 'sp2pluslcd_dc'];
      boolList.forEach( c => {
        if ( license[c] === 1 ) {
          licenseList.push({code: c, value: true});
        }
      });

      params.license = [...licenseList];

      if ( params.license.length === 0 ) {
        noty('Please select at least one option.', 'error');
        return false;
      }

      setIsGenerating(true);
      ajaxGenLicense(params)
        .then((res) => {
          if ( res.data.hasOwnProperty('error') ) {
            setOutput(res.data.error);
            setIsOutputError(true);
          } else {
            setOutput(res.data.license);
            setIsOutputError(false);
          }
          setIsGenerating(false);
        })
        .catch(e => {
          setIsGenerating(false);
          console.log(e.message);
        });
    } else {
      setIsGenerating(true);
      // activation key
      ajaxGenActivateCode(params)
        .then((res) => {
          if ( res.data.hasOwnProperty('error') ) {
            setOutput(res.data.error);
            setIsOutputError(true);
          } else {
            setOutput(res.data.license);
            setIsOutputError(false);
          }
          setIsGenerating(false);
        })
        .catch(e => {
          setIsGenerating(false);
          console.log(e.message);
        });
    }
  };

  const handleFieldChange = (event, val) => {
    let value = val !== undefined ? val : event.target.value,
      key = event.target.name;

    setLicense({ ...license, [key]: value });
  };

  const getFieldValue = (key) => {
    return license && license[key] !== undefined && license[key] !== null ? license[key] : '';
  };

  const getFieldPlaceholder = (key) => key === 'mac' ? '00-0B-DC-XX-XX-XX' : '';

  const getFieldErrorText = (key) => errors && errors[key] ? errors[key] : '';

  const checkFieldError = (key) => errors && errors[key];

  const buttonStyle = {
    height: '40px',
    width: '150px',
    fontSize: '12px'
  };

  return (
    <Page title="License Generator">
      <StyledCard>
        <StyledCardFormContent>
          <StyledMainGrid columnSpacing>
            {allFields.map((entry, index) => (
              <React.Fragment key={index}>
                {
                  entry['type'] === 'blank' && <Grid item xs={12} {...entry['gridProps']}/>
                }
                {
                  (entry['type'] === 'text' || entry['type'] === 'password') &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <TextField
                      label={entry['label']}
                      size="small"
                      type={entry['type']}
                      name={entry['key']}
                      value={getFieldValue(entry['key'])}
                      onChange={handleFieldChange}
                      error={checkFieldError(entry['key'])}
                      helperText={getFieldErrorText(entry['key'])}
                      placeholder={getFieldPlaceholder(entry['key'])}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={!!entry.disabled}
                      autoFocus={!!entry.autoFocus}
                      {...entry.multiline ? { multiline: true, rows: 4 } : {}}
                    />
                  </Grid>
                }
                {
                  entry['type'] === 'list' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl fullWidth disabled={!!entry.disabled} error={checkFieldError(entry['key'])}>
                      <InputLabel id={`select-helper-label-${entry['key']}`}>{entry['label']}</InputLabel>
                      <Select
                        labelId={`select-helper-label-${entry['key']}`}
                        label={entry['label']}
                        type={entry['type']}
                        name={entry['key']}
                        size="small"
                        value={getFieldValue(entry['key'])}
                        onChange={(e) => handleFieldChange(e)}
                        sx={{ width: '100%' }}
                      >
                        { (entry.dataset || []).map( c =>
                          <MenuItem value={c.value}>{c.label}</MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{getFieldErrorText(entry['key'])}</FormHelperText>
                    </FormControl>
                  </Grid>
                }
                { entry['type'] === 'radio' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControl>
                      <FormLabel id={entry['key']}>{entry['label']}</FormLabel>
                      <RadioGroup
                        name={entry['key']}
                        row
                        value={getFieldValue(entry['key'])}
                        onChange={handleFieldChange}
                      >
                        { (entry.dataset || []).map( c =>
                          <FormControlLabel value={c.value} control={<Radio />} label={c.label} />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                }
                {
                  entry['type'] === 'checkbox' &&
                  <Grid item xs={12} {...entry['gridProps']}>
                    <FormControlLabel
                      control={<Checkbox name={entry['key']} value={1} />}
                      onChange={(e, val) => handleFieldChange(e, val ? 1 : 0)}
                      checked={!!parseInt(getFieldValue(entry['key'], 10))}
                      label={entry['label']}
                    />
                  </Grid>
                }
              </React.Fragment>
            ))}
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Button variant="contained" disabled={isGenerating} size="normal" style={buttonStyle} sx={{ backgroundColor: 'green', mr: '20px' }} onClick={handleSave}>
                Generate
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Alert severity={isOutputError ? 'error' : 'success'} style={{fontSize: '16px'}}>
                <AlertTitle>
                  <span>{`License Key = ${output === '' ? '' : output}`}</span>
                  <IconButton
                    size="small"
                    sx={{ fontSize: '1rem', ml: 1, mb: 0.5 }}
                    onClick={(e) => {
                      copyToClipboard(output);
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <ContentCopyIcon fontSize="inherit" />
                  </IconButton>
                </AlertTitle>
              </Alert>
            </Grid>
          </StyledMainGrid>
        </StyledCardFormContent>
      </StyledCard>
    </Page>
  );
}
